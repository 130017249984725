<template>
  <section class="margin__md">
    <section class="container">
      <h2 class="text__center padding__sm">Meet Some of the Team</h2>
      <p
        class="text__center text__md padding__sm"
      >We ensure that all donation requests are handled by people that live and work in the region the request came from. This gives us a deep connection to the community which we are helping.</p>
    </section>
    <section class="container helpers">
      <div>
        <table>
            <tr>
                <th colspan="2">Mid-Mo</th>
                <th colspan="2">Southern Mo</th>
            </tr>
            <tr>
                <td>Shelly Cole</td>
                <td>Deidra Richter</td>
                <td>Caitlin Pierson</td>
                <td>Jennifer Cook</td>
            </tr>
            <tr>
                <td>Donald Hiatt</td>
                <td>Malinda Mitchell</td>
                <td>Tonya Jones</td>
                <td>Melinda Garcia</td>
            </tr>
            <tr>
                <td>Jenni Williams</td>
                <td>Emily Rowland</td>
                <td>Charity Mathis</td>
                <td>Marnie Mitchell</td>
            </tr>
            <tr>
                <td>Lisa Potter</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
            <tr>
                <th colspan="2">Kansas City</th>
                <th colspan="2">St. Louis</th>
            </tr>
            <tr>
                <td>Becca Robinson</td>
                <td>Sarah Goth</td>
                <td>Jamie Hiatt</td>
                <td>Amanda Higgins</td>
            </tr>
            <tr>
                <td>Beth Langston</td>
                <td>Melissa Alleman</td>
                <td>Lisa Kelam</td>
                <td>Lisa Matthews</td>
            </tr>
            <tr>
                <td>Angie Stuber</td>
                <td>&nbsp;</td>
                <td>Hilary Love</td>
                <td>Paul Smith</td>
            </tr>
            <tr>
                <th colspan="2">Illinois</th>
                <th colspan="2">OK/AR/TX</th>
            </tr>
            <tr>
                <td>Josh Fulton</td>
                <td>Bill Mitchell</td>
                <td>Sean McKee</td>
                <td>Sabra Jouett</td>
            </tr>
            <tr>
                <td>Adam Lofgren</td>
                <td>Suzanne Wilkins</td>
                <td>Alex Higeons</td>
                <td>Emily Machetta</td>
            </tr>
            <tr>
                <td>Rebecca Price</td>
                <td>Emily Browning</td>
                <td>Heather Kendall</td>
                <td>Jaimee Youngblood</td>
            </tr>
            <tr>
                <td>Jennifer Wright</td>
                <td>&nbsp;</td>
                <td>Jasmine Benedict</td>
                <td>&nbsp;</td>
            </tr>
        </table>
    </div>
    </section>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getRandomTeamMembers"])
  }
};
</script>

<style scoped lang="scss">
h2 {
  color: $pine;
}
.helpers {
  @include grid($cols: 1fr, $row-gap: 15px);
  padding: 5px;
}
.helper {
  @include grid($cols: 1fr 3fr, $col-gap: 10px, $align: center);
  .info {
    * {
      text-align: left;
    }
    .text__md {
      color: $pine;
    }
  }
  &:nth-child(even) {
    @include grid($cols: 1fr 3fr, $col-gap: 10px, $align: center);
    direction: rtl;
    .info {
      * {
        text-align: right;
      }
    }
  }
}
.avatar {
  border-radius: 50%;
  border: 5px solid $green;
  width: 150px;
  height: 150px;
}
@media (min-width: $md) {
  .helpers {
    @include flex($just: center, $wrap: wrap);
    max-width: 750px;
  }
  .helper {
    width: 325px;
    margin: 10px;
  }
}
td{
  padding: 5px;
}
</style>