<template>
  <footer class="pos__rel">
    <div class="container">
      <img
        src="https://cdn.fbhl.com/foundation/assets/karen-cutout-with-tgb-shirt-min.png"
        alt="The Giving Branch team member"
        width="100%"
        height="100%"
        class="cutout"
      />
      <span class="cutout-hold"></span>
      <div>
        <div class="grid">
          <div class="forms">
            <router-link
              to="/donation-request"
              name="Request financial assistance"
              class="btn btn__green"
            >
              Donation Request
              <font-awesome-icon icon="hands-helping" size="lg" class="ml-10" />
            </router-link>
            <router-link
              to="/scholarship"
              name="Read more about our scholarship"
              class="btn btn__green"
            >
              Scholarship
              <font-awesome-icon icon="graduation-cap" size="lg" class="ml-10" />
            </router-link>
            <router-link
              to="/car-giveaway"
              name="Learn about our gift of transportation"
              class="btn btn__green"
            >
              Transportation
              <font-awesome-icon icon="car" size="lg" class="ml-10" />
            </router-link>
          </div>
          <div class="icon">
            <font-awesome-icon icon="hand-holding-heart" size="3x" />
          </div>
          <div class="links">
            <router-link
              to="/recipient-stories"
              name="Read some stories from people we have helped"
              class="btn btn__green"
            >
              <font-awesome-icon icon="book-open" size="lg" class="mr-10" />Recipient Stories
            </router-link>
            <router-link
              to="/our-impact"
              name="Explore the impact we have had"
              class="btn btn__green"
            >
              <font-awesome-icon icon="people-carry" size="lg" class="mr-10" />Our Impact
            </router-link>
            <router-link
              to="/who-we-are"
              name="Learn more about The Giving Branch"
              class="btn btn__green"
            >
              <font-awesome-icon icon="users" size="lg" class="mr-10" />Who We Are
            </router-link>
          </div>
        </div>
        <div class="flex__center mt-15 mb-15">
          <a
            href="https://www.facebook.com/TheGivingBranch/"
            rel="noreferrer"
            target="_blank"
            class="social"
          >
            <font-awesome-icon :icon="['fab', 'facebook-square']" size="2x" />
          </a>
        </div>
        <div class="sponsors">
          <p class="text__bold text__md text__powder">Our Partners</p>
          <div class="sponsors-links flex__around flex__wrap">
            <a
              href="https://www.unitedway.org/#"
              rel="external nofollow noreferrer"
              target="_blank"
              name="View the United Way website"
              class="united"
            >
              <img
                src="https://cdn.fbhl.com/foundation/logos/united-way-lock-up-rgb-localized.jpg"
                alt="United Way logo"
                width="100%"
                height="100%"
              />
            </a>
            <a
              href="https://www.joemachenscdjr.com/"
              rel="external nofollow noreferrer"
              target="_blank"
              name="View the Joe Machens CDJR website"
              class="machens"
            >
              <img
                src="https://cdn.fbhl.com/foundation/gar-give/assets/Machens-CDJR-URL-Alternate-Color.png"
                alt="Joe Machens CDJR logo"
                width="100%"
                height="100%"
              />
            </a>
          </div>
        </div>
        <hr class="mb-15" />
        <Compliance />
      </div>
    </div>
  </footer>
</template>

<script>
import Compliance from "./Compliance";
export default {
  components: {
    Compliance
  }
};
</script>

<style scoped lang="scss">
footer {
  padding: 75px 0 25px 0;
  background-color: $pine;
}
.container,
.grid {
  @include grid($cols: 1fr, $row-gap: 25px, $align: center);
}
.cutout {
  display: none;
}
.icon {
  color: $frost;
  text-align: center;
}
.social {
  color: $powder;
  font-size: 1.25rem;
  text-align: center;
}
.sponsors {
  @include grid($cols: 1fr, $row-gap: 15px, $col-gap: 25px, $align: center);
  margin-bottom: 15px;
  p {
    text-align: center;
  }
}
.united {
  width: 100px;
}
.machens {
  height: 45px;
  width: 250px;
  margin: 10px;
}
.forms,
.links {
  @include grid($cols: 1fr, $row-gap: 10px);
}
hr {
  background-color: $frost;
  height: 2px;
}
@media (min-width: $md) {
  .forms {
    grid-area: forms;
  }
  .links {
    grid-area: links;
  }
  .icon {
    grid-area: icon;
  }
  .grid {
    grid-template-columns: 2fr 1fr 2fr;
    grid-column-gap: 25px;
    grid-template-areas: "links icon forms";
  }
}
@media (min-width: $lg) {
  .sponsors {
    grid-template-columns: 150px 1fr;
    p {
      text-align: left;
    }
  }
}
/* @media (min-width: $lg) {
  .container {
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 100px;
  }
  .cutout {
    display: block;
    @include pos-abs(
      $pos: absolute,
      $top: null,
      $left: 2%,
      $bottom: 0,
      $width: 325px,
      $height: 125%,
      $z: 0
    );
  }
} */
</style>